import type { ZudokuConfig } from "zudoku";
import { MyApiKeyService, ApiIdentityPlugin } from "./src/MyApiKeyService";
// Import the pre-generated sidebar structure
import { generatedSidebarDocs } from "./src/generated-sidebar";

// No longer need these imports and functions
// import fs from 'node:fs';
// import path from 'node:path';
// function generateSidebarItemsFromDir()...
// const stratus5BaseItems = ...
// const dollieItems = ...

const config: ZudokuConfig = {

  page: {
    logo: {
      src: {
        light: "/icon.svg",
        dark: "/icon-dark.svg",
      },
      width: "99px"
    }
  },
  topNavigation: [
    // { id: "docs", label: "Platform Docs" },
    { id: "docs", label: "Documentation" },
    { id: "api", label: "S5 API" },
    { id: "api-dollie", label: "Dollie CHQ API" }

  ],
  sidebar: {
    // Use the imported generated structure for the 'docs' sidebar
    docs: generatedSidebarDocs
  },
  redirects: [
    // Ensure the redirect points to a valid generated ID (assuming index exists)
    { from: "/", to: "/docs/stratus5/index" },
  ],
  apis: [
    {
      type: "file" as const,
      input: "./apis/s5.yaml",
      navigationId: "api",
    },
    {
      type: "file" as const,
      input: "./apis/control-hq.json",
      navigationId: "api-dollie",
    }
  ],
  docs: {
    files: "/pages/**/*.{md,mdx}", // This remains important for Zudoku to find the content
  },
  authentication: {
    type: "openid",
    clientId: "QG28Ct5AVF5rWWNHx3OfAjGrvtcH0oIj",
    issuer: "https://dev-jp3t88ajkczvb2nf.eu.auth0.com",
    scopes: ["openid", "profile", "email", "offline_access"],
    redirectToAfterSignIn: "/docs/stratus5/index",
    redirectToAfterSignUp: "/docs/stratus5/index",
    redirectToAfterSignOut: "/docs/stratus5/index",
  },
  protectedRoutes: [
    "/api/*",          // Protect all API documentation routes
    "/api-dollie/*",   // Protect all Dollie API routes
    "/private/*",      // Protect all private documentation routes
    "/api-keys",       // Protect the API keys management page
  ],
  apiKeys: MyApiKeyService,
  plugins: [
    ApiIdentityPlugin,
  ],
};

export default config;
