import { createApiKeyService } from "zudoku/plugins/api-keys";
import { createApiIdentityPlugin } from "zudoku/plugins";

// Helper function to generate UUIDs
const generateUUID = () => {
  // Use window.crypto if available (browser), otherwise use a fallback
  if (typeof window !== 'undefined' && window.crypto) {
    return window.crypto.randomUUID();
  } else {
    // Simple UUID fallback for server-side rendering
    return 'xxxx-xxxx-xxxx-xxxx'.replace(/[x]/g, () => {
      return Math.floor(Math.random() * 16).toString(16);
    });
  }
};

const now = new Date();
const thirtyDaysFromNow = new Date(now);
thirtyDaysFromNow.setDate(now.getDate() + 30);

const ninetyDaysFromNow = new Date(now);
ninetyDaysFromNow.setDate(now.getDate() + 90);

/**
 * Sample API keys for demonstration purposes.
 * In a real implementation, these would typically come from a database.
 */
const EXAMPLE_KEYS = [
  {
    id: "group-one-s5-staging",
    description: "Stratus5 API Auth",
    key: "Ym93ZUBzdHJhdHVzNS5jb206WXIqWkN5UDJaVw==", // Base64 encoded "api:prod-key-xyz-123"
    createdOn: now.toISOString(),
    expiresOn: ninetyDaysFromNow.toISOString(),
  },
  {
    id: "group-one-dollie-staging",
    description: "Dollie API Auth",
    key: "pZxvrm8P46zcF45V6hbsu6bxNg5pZedvm83reAP6e22097b3",
    createdOn: now.toISOString(),
    expiresOn: thirtyDaysFromNow.toISOString(),
  }
];

/**
 * Example implementation of an API Key Service that stores keys in memory.
 * This implementation demonstrates the basic functionality required for managing API keys:
 * - Listing all keys
 * - Creating new keys
 * - Deleting keys
 * - Rolling (regenerating) keys
 * - Updating key descriptions
 *
 * Note: This is a simple in-memory implementation for demonstration purposes.
 * In a real environment, you would typically:
 * - Store keys in a secure database
 * - Implement proper encryption for the key values
 * - Add validation for key operations
 * - Implement rate limiting and usage tracking
 * - Add audit logging for key operations
 */
let keys = [...EXAMPLE_KEYS];
export const MyApiKeyService = createApiKeyService({
  enabled: true,

  /*
   * Retrieves all API keys.
   * In a real implementation, this would typically:
   * - Filter expired keys
   * - Apply pagination
   * - Add sorting options
   * - Filter based on user permissions
   */
  getKeys: async () => {
    return keys;
  },

  /**
   * Creates a new API key.
   * Demonstrates:
   * - Generating secure random IDs and key values
   * - Handling optional expiration dates
   * - Setting creation timestamps
   */
  createKey: async (apiKey) => {
    const newKey = {
      id: generateUUID(),
      description: apiKey.description,
      key: `key-${generateUUID()}`,
      createdOn: new Date().toISOString(),
      expiresOn: apiKey.expiresOn,
    } as const;
    keys.push(newKey);
  },

  /**
   * Deletes an API key by ID.
   * In a real implementation, you might want to:
   * - Add soft delete functionality
   * - Archive deleted keys
   * - Add validation for protected keys
   */
  deleteKey: async (id) => {
    keys = keys.filter((key) => key.id !== id);
  },

  /**
   * Rolls (regenerates) an API key while maintaining its metadata.
   * This is useful when a key might have been compromised.
   * The key ID stays the same but gets a new value.
   */
  rollKey: async (id) => {
    const key = keys.find((k) => k.id === id);
    if (key) {
      key.key = `key-${generateUUID()}`;
    }
  },

  /**
   * Updates the description of an API key.
   * Demonstrates:
   * - Finding and updating specific keys
   * - Maintaining update timestamps
   */
  updateKeyDescription: async (apiKey) => {
    const key = keys.find((k) => k.id === apiKey.id);
    if (key) {
      key.description = apiKey.description;
    }
  },
});

// Helper for base64 encoding (for basic auth)
const base64Encode = (str: string): string => {
  if (typeof btoa !== 'undefined') {
    return btoa(str);
  }
  // Node.js environment fallback
  if (typeof Buffer !== 'undefined') {
    return Buffer.from(str).toString('base64');
  }
  // Fallback if neither is available
  return str;
};

// Create API Identity plugin for API authentication
export const ApiIdentityPlugin = createApiIdentityPlugin({
  getIdentities: async () => [


  ],
});
